
const dev = {
  BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000"
      : "https://api.inburg.com",
  IMAGE_URL: 'https://api.inburg.com/',
  RAZORPAY_API_KEY: 'rzp_test_N6D1ZMRbNPfHea',
  RAZORPAY_SECRET_KEY: 'qHFxOSoXFLqnz2HBgpeMmEmB'
};


const ENV = dev;

export default ENV;
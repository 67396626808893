import React, { useEffect, useState } from "react";
import { getReq } from "@utils/ApiHandler";
import { useDispatch } from "react-redux";
import { showToast } from "@redux/action";
import { useHistory } from "react-router-dom";

const Coupon = () => {

  const [category, setCategory] = useState([])

  const [chooseCategory, setChooseCategory] = useState({
    category_name: ""
  })
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    getAllCategory();
  }, [])

  const getAllCategory = () => {
    getReq(`/user/user-category`).then((res) => {
      if (res.status) {
        setCategory(res.data.data)
      } else {
        dispatch(showToast({ type: "error", message: res.error }));
      }
    });
  }

  const handleSelect = (event) => {
    const { name, value } = event.target
    setChooseCategory({ ...chooseCategory, [name]: value })
  }


  const handleClick = () => {
    if (chooseCategory.category_name !== '') {
      history.push(`/reward/${chooseCategory.category_name}`)
    } else {
      dispatch(showToast({ type: 'error', message: 'Please select category.' }))
    }
  }

  return (
    <div className="max-w-[480px] mx-auto min-h-screen bg-white flex flex-col relative">
      <div className="box-1 mb-12">
        <div className="w-[285px] mx-auto mt-10">
          <img
            className="w-full  object-cover mx-auto "
            src={process.env.PUBLIC_URL + "/page6/coupon.png"}
          />
        </div>
        <div className="text-center leading-10">
          <h1 className="text-[25px] font-semibold italic">
            Congratulations{""}
          </h1>
          <h2 className="text-[#F7991C] text-[30px] tracking-wider w-[80%] mx-auto font-light">
            You won a
          </h2>
          <p className="text-[40px] font-semibold text-[#F7991C]">custom coupon</p>
        </div>

      </div>
      <div className=" w-[100%] bottom-0 flex flex-col gap-16 bg-black rounded-b-none rounded-[50px] p-10 text-white text-center">
        <label className="text-[20px] font-light" htmlFor="underline_select">
          Select a Category
        </label>
        <select
          id="underline_select"
          className="text-[18px] px-0 mx-auto w-8/12 text-base font-light flex justify-center text-white bg-transparent border-0 border-b-[1px] border-gray-800 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 "
          onChange={handleSelect}
          name='category_name'
        >
          <option value="">Choose</option>
          {
            category.map((item, index) => {
              return (
                <option className="bg-black text-white" selected="" key={index} value={item}>
                  {item}
                </option>
              )
            })
          }
        </select>
        <div>
          <button
            type="button"
            className="text-white text-base px-[89px] rounded-3xl py-[13px] bg-[#222222] hover:bg-[#222222]/90 "
            onClick={handleClick}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Coupon;

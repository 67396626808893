import React from "react";

const CouponSuccessfull = () => {
  return (
    <section className="flex flex-col justify-center items-center min-h-screen max-w-[480px] mx-auto text-black  bg-black gap-4 px-5">
      <div className="w-[252px]">
        <img
          className="w-full h-full object-cover mx-auto"
          src={process.env.PUBLIC_URL + "/page9/successfully.png"}
        />
      </div>

      <div className="flex flex-col justify-center items-center w-9/12 text-center mx-auto text-white">
        <p className="text-xl font-light">
          <span className="font-normal italic">Congratulations!</span> <br />
          Your coupon code for
          <span className="block text-3xl font-normal text-[#F7991C] my-3">
            ₹<span className="text-5xl">500</span>
          </span>
          from
        </p>
        <img className="my-6" src="/image/Brandw.png" />
        <p className="text-[20px] font-light">
          has been successfully sent
          <br /> to your mobile number.
        </p>
      </div>
    </section>
  );
};

export default CouponSuccessfull;

import * as yup from 'yup'

// const number=()

export const MobileValidation = yup.object().shape({
    mobileNumber: yup.string().required('Please enter contact number.')
        .matches(/^[0-9\b]+$/, 'Please enter only number')
        .min(10, "Please enter valid number")
        .max(10, 'Please enter valid number'),
})


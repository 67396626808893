import { IoIosArrowBack } from "react-icons/io";
import React from "react";

const PaymentMethod = () => {
  return (
    <div className="bg-black min-h-screen text-white w-screen place-items-center max-w-[480px] mx-auto px-2">
      <div className="flex flex-row justify-start text-[15px] items-center py-4 text-dark">
        <div className="w-2/12">
          <IoIosArrowBack className="text-xl" />
        </div>
        <div className="w-8/12 text-center text-[15px]">
          <p className="ml-auto font-light">Choose Payment Method</p>
        </div>
      </div>
      <div className="mt-6 font-light w-8/12 mx-auto">
        <ul className="">
          <li className="flex items-center justify-center mt-0 border-b-[0.25px] border-b-slate-800 h-20">
            <p className="text-[20px]">Preferred Option</p>
          </li>

          <li className="flex items-center text-[18px] border-b-[0.25px] border-b-slate-800 hover:bg-zinc-800 h-20">
            <div className="w-1/3 pl-10 mr-10">
              <img src="/image/Paytm_white.png" alt="" />
            </div>
            <p>Paytm</p>
          </li>

          <li className="flex items-center text-[18px] border-b-[0.25px] border-b-slate-800 hover:bg-zinc-800 h-20">
            <div className="w-1/3 pl-10 mr-10">
              <img src="/image/phonepe 1.png" alt="" />{" "}
            </div>
            <p>PhonePe</p>
          </li>

          <li className="flex items-center text-[18px] border-b-[0.25px] border-b-slate-800 hover:bg-zinc-800 h-20">
            <div className="w-1/3 pl-10 mr-10">
              <img src="/image/phonepe 2.png" alt="" />{" "}
            </div>
            <p>GPay</p>
          </li>

          <li className="flex items-center text-[18px] border-b-[0.25px] border-b-slate-800 hover:bg-zinc-800  h-20">
            <div className="w-1/3 pl-10 mr-10">
              <img src="/image/phonepe 3.png" alt="" />{" "}
            </div>
            <p>Jio Money</p>
          </li>

          <li className="flex items-center text-[18px] border-b-[0.25px] border-b-slate-800 hover:bg-zinc-800 h-20">
            <div className="w-1/3 pl-10 mr-10">
              <img src="/image/phonepe 4.png" alt="" />{" "}
            </div>
            <p>Mobikwik</p>
          </li>
        </ul>
      </div>
      <div className="pt-8 grid place-items-center">
        <button
          type="button"
          className="text-white text-base px-[60px] mt-4 rounded-3xl py-[13px] bg-[#222222] hover:bg-[#222222]/90 "
        >
          Pay <span className="text-[12px]">&#8377;</span> 2500.00 Securely
        </button>
      </div>
    </div>
  );
};

export default PaymentMethod;

import { Alert as MuiAlert, Snackbar } from "@material-ui/core";
import React from "react";
import "./input.css";
import { HIDE_TOAST } from "@redux/action/actionConstants";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "@pages/notFound";
import ProtectedRoutes from "./ProtectedRoutes";
import Profile from "@pages/profile";
import Orderconfirmation from "@pages/Orderconfirmation";
import Paymentmode from "@pages/Paymentmode";
import PaymentMethod from "@pages/PaymentMethod";
import CouponSuccessfull from "@pages/CouponSuccessfull";
import CouponCode from "@pages/CouponCode";
import Reward from "@pages/Reward";
import Coupon from "@pages/Coupon";
import HomePage from "@pages/HomePage";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AuthContext = React.createContext({});

const Routes = ({ }) => {
  const dispatch = useDispatch();
  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };

  return (
    <>
      <Snackbar
        open={isVisible}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Switch>
        <Route path={"/"} exact component={HomePage} />
        <Route path={"/pay-invoice"} exact component={Orderconfirmation} />
        <Route path={"/pay-merchant"} exact component={Orderconfirmation} />
        <Route path={"/payment-mode"} exact component={Paymentmode} />
        <Route path={"/payment-method"} exact component={PaymentMethod} />
        <Route path={"/coupon"} exact component={Coupon} />
        <Route path={"/couponcode"} exact component={CouponCode} />
        <Route path={"/reward/:chooseCategory"} exact component={Reward} />
        <Route
          path={"/couponsuccessfull"}
          exact
          component={CouponSuccessfull}
        />
        {/* <Route path={'/login'} exact component={LoginPage} /> */}
        <ProtectedRoutes path={"/profile"} exact component={Profile} />

        <Route path={"/*"} exact component={NotFound} />
      </Switch>
    </>
  );
};

const App = () => {
  const [user, setUser] = React.useState({});
  return (
    <Router>
      <AuthContext.Provider value={{ user, setUser }}>
        <Routes />
      </AuthContext.Provider>
    </Router>
  );
};
export default App;

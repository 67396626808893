import { IoIosArrowBack } from "react-icons/io";
import React from "react";

const Paymentmode = () => {
  return (
    <div className="w-screen place-items-center min-h-screen max-w-[480px] mx-auto bg-black text-white px-2">
      <div className="flex flex-row justify-start text-[15px] items-center py-4 text-dark w-9/12">
        <IoIosArrowBack className="text-xl w-1/12" />
        <div className="ml-auto font-light text-center w-8/12">
          <p> Choose Payment Mode</p>
        </div>
      </div>
      <div className="mt-12 font-light w-8/12 mx-auto">
        <ul className="space-y-10">
          <li className="flex items-center border-b-[0.25px] border-b-gray-700 space-x-10 pb-4 pl-6">
            <img src="/image/UPI.png" alt="" />
            <div>
              <p>UPI</p>
              <p className="font-light text-[12px]">Google Pay, Paytm & more</p>
            </div>
          </li>

          <li className="flex items-center border-b-[0.25px] border-b-gray-700 space-x-10 pb-4 pl-6">
            <img src="/image/Netbank.png" alt="" />
            <div>
              <p>Net Banking</p>
              <p className="font-light text-[12px]">All Indian Banks</p>
            </div>
          </li>

          <li className="flex items-center border-b-[0.25px] border-b-gray-700 space-x-10 pb-4 pl-6">
            <img src="/image/Wallet.png" alt="" />
            <div>
              <p>Wallet</p>
              <p className="font-light text-[12px]">PhonePe and more</p>
            </div>
          </li>

          <li className="flex items-center border-b-[0.25px] border-b-gray-700 space-x-10 pb-4 pl-6">
            <img src="/image/Card.png" alt="" />
            <div>
              <p>Card</p>
              <p className="font-light text-[12px]">Visa, MasterCard, RuPay</p>
            </div>
          </li>
        </ul>
        <div className="mt-8 grid place-items-center">
          <p className="text-center mt-4 font-light text-[20px]">You Pay</p>
          <p className="text-[52px]">
            <span className="text-[32px]">&#8377;</span> 2500.00
          </p>
          <button
            type="button"
            className="text-white text-base px-[89px] mt-6 mb-3 rounded-3xl py-[13px] bg-[#222222] hover:bg-[#222222]/90 "
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Paymentmode;
